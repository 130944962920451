<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">Swords, People, & Other “Stuff”</h1>
      </v-card-title>
      <v-card-subtitle> </v-card-subtitle>
    </v-banner>
    <v-row>
      <v-col cols="12" class="mt-4 mx-4">
        <h3 class="ac">
          Before we began the historic 8 Samurai Series we did produce swords
          for fellow samurai practitioners who wished to own good blades (albeit
          not forged by Masters); with genuine antiques fittings in a theme, for
          the better enjoyment of their martial art training. Here are some of
          those swords, plus people and random photos - just for fun!.
          <br /><br />
          Heck, what’s the good of having a website if you can’t have some fun!
          <br />
          <br />
          -A. Taum Dell’Armo,<br />
          American Samurai
        </h3>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="mx-4 grey--text"
        >Click on the pictures to open the gallery. Use the arrow keys to
        browse.</v-col
      >
    </v-row>

    <v-row>
      <v-row height="600" hide-delimiters>
        <v-col v-for="(item, no) in items" :key="item.id">
          <v-card class="mx-auto" max-width="344" @click="openPicture(no)">
            <v-img :src="item.pic" height="200px">
              <div v-if="item.hasSold" class="sold">Sold</div>
            </v-img>

            <v-card-title>
              <span class="ac text-break"> {{ item.text }}</span>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog
      v-model="showDialog"
      min-width="350"
      max-width="80%"
      @keydown.left="picBack()"
      @keydown.right="picForward()"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ items[activePicture].text }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showDialog = false">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-img contain max-height="800" :src="items[activePicture].pic">
          <v-btn
            x-large
            style="position: absolute; left: -0px; top: 40%"
            @click="picBack()"
          >
            <v-icon x-large color="">arrow_back</v-icon>
          </v-btn>
          <v-btn
            x-large
            style="position: absolute; right: -10px; top: 40%"
            @click="picForward()"
          >
            <v-icon x-large color="">arrow_forward</v-icon>
          </v-btn>
        </v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "About",
  components: {},
  metaInfo() {
    return {
      title: "Swords & People",
      meta: [
        {
          name: "description",
          content:
            "Before we began the historic 8 Samurai Series we did produce swords for fellow samurai",
        },
        {
          property: "og:title",
          content: "American Samurai",
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      showDialog: false,
      activePicture: 1,
      items: [
        {
          id: 1,
          text: "James Campbell, Monterey, CA: Incredible Sensei & trusted friend",
          pic: require("@/assets/swordsandpeople/1.jpg"),
          hasSold: false,
        },
        {
          id: 2,
          text: "Dragon Fire",
          pic: require("@/assets/swordsandpeople/2.jpg"),
          hasSold: true,
        },
        {
          id: 3,
          text: "Dragon Fire Tsuba",
          pic: require("@/assets/swordsandpeople/3.jpg"),
          hasSold: false,
        },
        {
          id: 4,
          text: "Zen Sword",
          pic: require("@/assets/swordsandpeople/4.jpg"),
          hasSold: true,
        },
        {
          id: 5,
          text: "Sword of legend",
          pic: require("@/assets/swordsandpeople/5.jpg"),
          hasSold: true,
        },
        {
          id: 6,
          text: "Lion & The Rose (Foo-Dog & Peony)",
          pic: require("@/assets/swordsandpeople/6.jpg"),
          hasSold: false,
        },
        {
          id: 7,
          text: "Lion & The Rose",
          pic: require("@/assets/swordsandpeople/7.jpg"),
          hasSold: true,
        },
        {
          id: 8,
          text: "Heaven & Earth",
          pic: require("@/assets/swordsandpeople/8.jpg"),
          hasSold: true,
        },
        {
          id: 9,
          text: "Heaven & Earth",
          pic: require("@/assets/swordsandpeople/9.jpg"),
          hasSold: false,
        },
        {
          id: 10,
          text: "Heaven & Earth/Tsuba",
          pic: require("@/assets/swordsandpeople/10.jpg"),
          hasSold: false,
        },
        {
          id: 11,
          text: "Mon",
          pic: require("@/assets/swordsandpeople/11.jpg"),
          hasSold: false,
        },
        {
          id: 12,
          text: "Gold Fukurin/Destiny",
          pic: require("@/assets/swordsandpeople/12.jpg"),
          hasSold: false,
        },
        {
          id: 13,
          text: "Tsuba/Legend",
          pic: require("@/assets/swordsandpeople/13.jpg"),
          hasSold: false,
        },
        {
          id: 14,
          text: "Tsuba/Legend",
          pic: require("@/assets/swordsandpeople/14.jpg"),
          hasSold: false,
        },
        {
          id: 15,
          text: "Menuki/Legend",
          pic: require("@/assets/swordsandpeople/15.jpg"),
          hasSold: false,
        },
        {
          id: 16,
          text: "Tsuba/Destiny",
          pic: require("@/assets/swordsandpeople/16.jpg"),
          hasSold: false,
        },
        {
          id: 17,
          text: "F/K - Destiny",
          pic: require("@/assets/swordsandpeople/17.jpg"),
          hasSold: false,
        },
        {
          id: 18,
          text: "Kojii",
          pic: require("@/assets/swordsandpeople/18.jpg"),
          hasSold: false,
        },
        {
          id: 19,
          text: "The legend, Robert E. Haynes",
          pic: require("@/assets/swordsandpeople/19.jpg"),
          hasSold: false,
        },
        {
          id: 20,
          text: "Mukansa Master, Yoshindo Yoshihara",
          pic: require("@/assets/swordsandpeople/20.jpg"),
          hasSold: false,
        },
        {
          id: 21,
          text: "Shihan Franklin Hargrove 10th Dan, Martial Arts Hall of Fame",
          pic: require("@/assets/swordsandpeople/21.jpg"),
          hasSold: false,
        },
        {
          id: 22,
          text: "Atop the spiritual mountain, Miyasaki, Japan",
          pic: require("@/assets/swordsandpeople/22.jpg"),
          hasSold: false,
        },
        {
          id: 23,
          text: "Master Saito of Saitama, Japan",
          pic: require("@/assets/swordsandpeople/23.jpg"),
          hasSold: false,
        },
        {
          id: 24,
          text: "Can never have enough Samurai books",
          pic: require("@/assets/swordsandpeople/24.jpg"),
          hasSold: false,
        },
        {
          id: 25,
          text: "Our Research Dept at work",
          pic: require("@/assets/swordsandpeople/25.jpg"),
          hasSold: false,
        },
        {
          id: 26,
          text: "Arm exercises are really paying off!",
          pic: require("@/assets/swordsandpeople/26.jpg"),
          hasSold: false,
        },
        {
          id: 27,
          text: "The greatest living samurai smith, Gassan Sadatoshi and his son, Sadanobu, at the Master's home. Over 9 centuries of samurai sword tradition!",
          pic: require("@/assets/swordsandpeople/update3/U3-1.jpg"),
          hasSold: false,
        },
        {
          id: 28,
          text: "The 8 Samurai Team: Hiro Lennon &  Fumika Tanokashira",
          pic: require("@/assets/swordsandpeople/update3/U3-2.jpg"),
          hasSold: false,
        },
        {
          id: 29,
          text: "At the Dai Token Ichi in Tokyo; largest sword show in the world",
          pic: require("@/assets/swordsandpeople/update3/U3-3.jpg"),
          hasSold: false,
        },
        {
          id: 30,
          text: "Mukansa Master & valued friend, Matsuba Kunimasa, world-famous swordsmith",
          pic: require("@/assets/swordsandpeople/update3/U3-4.jpg"),
          hasSold: false,
        },
        {
          id: 31,
          text: "Master Hisashi Saito; Me, on jet-lag; and Fred Weissberg, Antique Sword Expert and dealer  - nihonto.com ",
          pic: require("@/assets/swordsandpeople/update3/U3-5.jpg"),
          hasSold: false,
        },
      ],
    };
  },
  methods: {
    picForward() {
      if (this.activePicture + 1 > this.items.length) {
        this.activePicture = 0;
      } else this.activePicture++;
    },
    picBack() {
      if (this.activePicture === 0) {
        this.activePicture = this.items.length - 1;
      } else this.activePicture--;
    },
    openPicture(id) {
      if (typeof this.items[id] !== "undefined") {
        this.activePicture = id;
        this.showDialog = true;
      }
    },
  },
};
</script>
<style>
.footerText {
}
div.sold {
  height: 25px;
  width: 180px;
  background-color: #9c1b21;
  position: absolute;
  top: 40px;
  right: -10px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  white-space: nowrap;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  font-family: "Apple Chancery", "Helvetica Neue", Helvetica;
}

.sold:before {
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;
  border-width: 37px;
  border-left-width: 53px;
  border-right-width: 26px;
  content: "";
  display: block;
  left: -49px;
  position: absolute;
  width: 141%;
  border-top-width: 0px;
  border-bottom-color: #9c1b21;
  top: -2px;
  z-index: -1;
}
</style>
