import Vue from "vue";
import Vuex from "vuex";
// import Cookies from "js-cookie";
// import router from "@/router";
import pathify from "@/plugins/vuex-pathify";

const BUILD_NUMBER = "2";
const VERSION_NUMBER = "8";
const BUILD_NAME = "CSC";
// const COOKIE_OPTIONS = { expires: 7 };
// const USER_COOKIE_NAME = "user";
// const USE_COOKIES = true;

Vue.use(Vuex);
const state = {
  versionNumber: VERSION_NUMBER,
  versionName: BUILD_NAME,
  buildNumber: BUILD_NUMBER,
};
const getters = {};
const mutations = {};
const actions = {};

const store = new Vuex.Store({
  mutations,
  actions,
  state,
  getters,
  plugins: [pathify.plugin],
});

export default store;

export const ROOT_DISPATCH = Object.freeze({ root: true });
